export default [
  {
    region: '台灣',
    callingCode: '+886',
    regionCode: 'TW',
  },
  {
    region: '香港',
    callingCode: '+852',
    regionCode: 'HK',
  },
  {
    region: '馬來西亞',
    callingCode: '+60',
    regionCode: 'MY',
  },
  {
    region: '新加坡',
    callingCode: '+65',
    regionCode: 'SG',
  },
];
