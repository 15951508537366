<template>
  <div>
    <div class="text-14 line-height-22 text-font-grayscale-1 mb-2">
      {{ $t('已綁定') }}
    </div>
    <div class="d-flex justify-content-between">
      <div class="line-height-28">{{ bindedData }}</div>
      <!-- todo: 尚未開發解除綁定功能，先做隱藏 -->
      <!-- <div class="line-height-28 text-primary cursor-pointer">
        {{ $t('解除綁定') }}
      </div> -->
    </div>
    <hr class="tab-hr mb-0 mt-2" />
  </div>
</template>

<script>
export default {
  props: {
    bindedData: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-hr {
  border-top: 1px solid #dcdfe5;
  width: 100%;
}
</style>
