<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('綁定信箱')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div class="mobile-container w-100 p-3 p-md-4">
      <template v-if="isBindedMobile">
        <BindedContent :binded-data="userData.phone"></BindedContent>
      </template>
      <template v-else>
        <FormMobile
          ref="FormMobile"
          send-type="bindAccount"
          @bind-fail="isBindFailModalShow = true"
        ></FormMobile>
        <div>
          <b-button variant="primary" size="md" @click="bindMobile">
            {{ $t('綁定') }}
          </b-button>
        </div>
      </template>
    </div>
    <BindNotice class="mt-md-3 mx-3 mx-md-0"></BindNotice>
    <modal-confirm
      v-model="isBindFailModalShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="isBindFailModalShow = false"
    >
      {{ $t('綁定失敗') }}<br />
      {{
        $t('您使用的{type}已與其他黑嘉嘉圍棋教室帳號綁定。', {
          type: isEmailSignUp ? '手機號碼' : '信箱',
        })
      }}
    </modal-confirm>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import BindNotice from '@/components/Personal/BindNotice.vue';
import BindedContent from '@/components/Personal/BindedContent.vue';
import FormMobile from '@/components/Form/FormMobile.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';

export default {
  components: {
    BaseHeader,
    BindNotice,
    BindedContent,
    FormMobile,
    ModalConfirm,
  },
  props: {
    isBind: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBindFailModalShow: false,
    };
  },
  computed: {
    isEmailSignUp() {
      return this.$store.getters['user/isEmailSignUp'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    isBindedMobile() {
      return this.$store.getters['user/isBindedMobile'];
    },
  },
  methods: {
    bindMobile() {
      this.$refs.FormMobile.onSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-container {
  @media screen and (min-width: 768px) {
    background: white;
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
    border-radius: 10px;
  }
  .title {
    @media screen and (min-width: 768px) {
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: $font-grayscale-1;
      padding: 16px 6px 16px 16px;
    }
  }
  .btn-primary {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 240px;
    }
  }
}
.error {
  margin-top: 4px;
  height: 16px;
  color: $negative;
  font-size: 12px;
  text-align: start;
}
.success-wrapper {
  height: calc(100vh - 56px);
  i {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $positive;
    color: #ffffff;
    font-size: 66px;
  }
  span {
    color: $font-grayscale-2;
  }
}
</style>
