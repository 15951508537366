<template>
  <b-form class="mobile-form" @submit.prevent="onSubmit">
    <b-form-group
      label-for="phoneNum"
      :label="$device.isMobile ? null : '手機號碼'"
      class="phoneNum-input"
    >
      <b-input-group v-if="$device.isMobile">
        <b-button
          class="region d-flex align-items-center justify-content-between pl-3 text-center"
          size="md"
          @click="isRegionEditing = true"
        >
          <span>{{ formData.currentCallingCode }}</span>
          <i class="icon-Chevron-down"></i>
        </b-button>
        <b-form-input
          id="phoneNum"
          ref="phoneNum"
          v-model="$v.formData.phoneNum.$model"
          name="phoneNum"
          aria-describedby="input-phoneNum-feedback"
          type="tel"
          :placeholder="$t('請輸入手機號碼')"
          :state="formData.isPhoneNumValid"
          @input="phoneNumInputHandler"
        ></b-form-input>
      </b-input-group>
      <b-input-group v-else>
        <b-dropdown
          toggle-class="customDropdown"
          variant="none"
          :text="formData.currentCallingCode"
          no-caret
          size="md"
        >
          <template #button-content>
            <div class="d-flex align-items-center">
              {{ formData.currentCallingCode }}
              <i class="icon-Chevron-down ml-2" style="color: #ff855e"></i>
            </div>
          </template>
          <b-dropdown-item
            v-for="region in regionCodes"
            :key="region.regionCode"
            @click="changeCode(region)"
            >{{ $t(region.region) }} {{ region.callingCode }}
          </b-dropdown-item>
        </b-dropdown>
        <b-form-input
          id="phoneNum"
          ref="phoneNum"
          v-model="$v.formData.phoneNum.$model"
          name="phoneNum"
          aria-describedby="input-phoneNum-feedback"
          type="tel"
          :placeholder="$t('請輸入手機號碼')"
          :state="formData.isPhoneNumValid"
          @input="phoneNumInputHandler"
        ></b-form-input>
      </b-input-group>
      <b-form-invalid-feedback
        id="input-phoneNum-feedback"
        :state="formData.isPhoneNumValid"
        class="mt-0"
      >
        <div v-if="!$v.formData.phoneNum.required" class="error">
          {{ $t('*請輸入手機號碼') }}
        </div>
        <div v-else-if="!$v.formData.phoneNum.numeric" class="error">
          {{ $t('*手機號碼必須為數字') }}
        </div>
        <div v-else-if="!$v.formData.phoneNum.isValid" class="error">
          {{ $t('*請輸入正確的手機號碼') }}
        </div>
        <div v-else-if="backendError === 'User not exist!'" class="error">
          {{ $t('*手機號碼尚未註冊') }}
        </div>
        <div v-else-if="backendError === 'User already exist!'" class="error">
          {{ $t('該使用者已註冊或已綁定') }}
        </div>
        <div
          v-else-if="backendError === 'Cannot send message in limit time'"
          class="error"
        >
          {{ $t('*操作過於頻繁，請稍後再嘗試') }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="w-100 d-flex align-items-end">
      <b-form-group
        label-for="verificationCode"
        :label="$device.isMobile ? null : '驗證碼'"
        class="verificationCode-input w-100 mr-1"
      >
        <b-form-input
          id="verificationCode"
          ref="verificationCode"
          v-model="$v.formData.verificationCode.$model"
          name="verificationCode"
          aria-describedby="input-verificationCode-feedback"
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          autocomplete="one-time-code"
          :placeholder="$t('請輸入驗證碼')"
          :state="formData.isVerificationCodeValid"
          @input="verificationCodeInputHandler"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-verificationCode-feedback"
          :state="formData.isVerificationCodeValid"
          class="mt-0"
        >
          <div v-if="!$v.formData.verificationCode.required" class="error">
            {{ $t('*請輸入驗證碼') }}
          </div>
          <div
            v-else-if="
              backendError === 'Code not correct' ||
              backendError === 'body Missing verificationId'
            "
            class="error"
          >
            {{ $t('*驗證碼錯誤') }}
          </div>
          <div v-else-if="backendError === 'Message is overdue'" class="error">
            {{ $t('*驗證碼已逾期') }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button
        class="send-verification-button ml-1"
        :class="{
          countdown: isCooldown,
          disabled: isCooldown,
          invalid:
            formData.isVerificationCodeValid == false &&
            (!$v.formData.verificationCode.required || backendError),
        }"
        variant="primary"
        size="md"
        @click="sendMobileOtp()"
      >
        {{ isCooldown ? `${nowSec}秒...` : btnText }}
      </b-button>
    </div>
    <action-sheet
      v-if="isRegionEditing && $device.isMobile"
      :title="$t('國家/地區')"
      :touch-move-to-close="true"
      sheet-height="158"
      @close="closeEdit"
    >
      <ul class="list-group">
        <li
          v-for="regionCode in regionCodes"
          :key="regionCode.code"
          class="avatar list-group-item-action d-flex align-items-center pl-3"
          @click="sheetEvent(regionCode.callingCode, regionCode.regionCode)"
        >
          <span class="mr-2">{{ $t(regionCode.region) }}</span>
          <span>{{ regionCode.callingCode }}</span>
        </li>
      </ul>
    </action-sheet>
  </b-form>
</template>

<script>
import {required, numeric} from 'vuelidate/lib/validators';
import {
  isValidPhoneNumber,
  getNationalPhoneNumber,
  getInternationalPhoneNumber,
  getUsernameByInternationalPhoneNumber,
} from '@/lib/validation/phoneNumber';
import BackEvent from '@/lib/base/backEvent.js';
import {clientVersion} from '@/constant/env';
import regionCodes from '@/config/regionCodes';

import ActionSheet from '@/components/Base/ActionSheet.vue';
import userService from '@/services/user';
import {removeLocalData} from '@/lib/base/localData';

export default {
  components: {
    ActionSheet,
  },
  props: {
    sendType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nowSec: 180,
      totalSec: 180,
      timer: null,
      formData: {
        phoneNum: '',
        verificationCode: '',
        verificationId: '',
        isPhoneNumValid: null,
        isVerificationCodeValid: null,
        currentCallingCode: '',
        currentRegionCode: '',
      },
      backendError: '',
      isCooldown: false,
      btnText: this.$t('傳送驗證碼'),
      clientVersion,
      isRegionEditing: false,
      getNationalPhoneNumber,
      getInternationalPhoneNumber,
      regionCodes,
    };
  },
  validations: {
    formData: {
      phoneNum: {
        required,
        numeric,
        isValid: (value, vm) => {
          if (vm.phoneNum === '') {
            return false;
          } else {
            return isValidPhoneNumber(vm.phoneNum, vm.currentRegionCode);
          }
        },
      },
      verificationCode: {required},
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    internationalPhoneNumber() {
      if (!this.isValidPhoneNumber) {
        return '';
      }

      return getInternationalPhoneNumber(
        this.formData.currentCallingCode + this.formData.phoneNum
      );
    },
    username() {
      if (!this.isValidPhoneNumber) {
        return '';
      }

      return getUsernameByInternationalPhoneNumber(
        this.internationalPhoneNumber
      );
    },
    isValidPhoneNumber() {
      return isValidPhoneNumber(
        this.formData.currentCallingCode + this.formData.phoneNum
      );
    },
  },
  mounted() {
    this.formData.currentRegionCode = this.isCN ? 'CN' : 'TW';
    this.formData.currentCallingCode = this.isCN ? '+86' : '+886';
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    closeModal() {
      this.isModalShow = false;
      this.$router.push({name: 'login'});
    },
    onSubmit() {
      if (this.isLoading) return;
      let invalid = false;
      invalid =
        this.$v.formData.phoneNum.$invalid ||
        this.$v.formData.verificationCode.$invalid;

      this.formData.isPhoneNumValid = null;
      this.formData.isVerificationCodeValid = null;

      if (invalid) {
        this.$v.$touch();
        this.formData.isPhoneNumValid = this.validatePhoneNum() ? null : false;
        this.formData.isVerificationCodeValid = false;
        this.focusInput();
      } else {
        // todo: 預計將有使用到發送手機驗證碼表單的元件統一到這裡，之後方便修改或調整！
        switch (this.sendType) {
          case 'bindAccount':
            this.setUsernameByOtp();
            break;
        }
        // return this.validateResetPasswordOtp();
      }
    },
    async sendMobileOtp() {
      this.$v.$touch();
      this.formData.isPhoneNumValid = null;

      if (!this.validatePhoneNum()) {
        this.formData.isPhoneNumValid = false;
        this.focusInput();
      } else {
        // todo: 預計將有使用到發送手機驗證碼表單的元件統一到這裡，之後方便修改或調整！
        switch (this.sendType) {
          case 'bindAccount':
            this.bindAccountSendOtp();
            break;
        }
      }
    },
    async bindAccountSendOtp() {
      this.$store.commit('env/setIsLoading', true);
      const data = {
        internationalPhoneNumber: this.internationalPhoneNumber,
        type: 'PHONE_SET',
      };
      try {
        const result = await userService.bindAccountSendOtp(data);
        this.$store.commit('env/setIsLoading', false);
        this.$Message.success(this.$t('已發送驗證碼'));
        this.formData.verificationId = result;
        this.isCooldown = true;
        this.countdown();
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.isCooldown = false;
        this.backendError = error.response.data.message;
        if (this.backendError === 'User already has phone!') {
          this.$emit('bind-fail');
        } else if (this.backendError !== '') {
          this.formData.isPhoneNumValid = false;
        }
      }
    },
    async setUsernameByOtp() {
      this.$store.commit('env/setIsLoading', true);
      try {
        const data = {
          type: 'PHONE_SET',
          verificationId: this.formData.verificationId,
          verificationCode: this.formData.verificationCode,
        };
        await userService.setUsernameByOtp(data);
        removeLocalData('user');
        userService.getUserData?.cache.clear();
        await this.$store.dispatch('user/loginWithJwt');
        this.$store.commit('env/setIsLoading', false);
        this.$Message.success(this.$t('綁定成功'));
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.backendError = error.response.data.message;
        if (this.backendError !== '') {
          this.formData.isVerificationCodeValid = false;
          this.$emit('bind-fail');
        }
      }
    },
    async countdown() {
      this.nowSec = this.totalSec;
      this.timer = setInterval(() => {
        if (this.nowSec === 0) {
          clearInterval(this.timer);
          this.isCooldown = false;
          this.btnText = this.$t('再一次傳送');
        } else {
          this.nowSec -= 1;
        }
      }, 1000);
    },
    phoneNumInputHandler() {
      this.backendError = '';
      this.formData.isPhoneNumValid = null;
    },
    verificationCodeInputHandler() {
      this.backendError = '';
      this.formData.isVerificationCodeValid = null;
    },
    validatePhoneNum() {
      const {$error} = this.$v.formData.phoneNum;
      return !$error;
    },
    validateVerificationCode() {
      const {$error} = this.$v.formData.verificationCode;
      return !$error;
    },
    focusInput() {
      if (!this.validatePhoneNum()) {
        this.$refs.phoneNum.focus();
      } else if (!this.validateVerificationCode()) {
        this.$refs.verificationCode.focus();
      } else {
        return false;
      }
    },
    changeCode(region) {
      this.formData.currentCallingCode = region.callingCode;
      this.formData.currentRegionCode = region.regionCode;
    },
    closeEdit() {
      BackEvent.popEvent();
      this.isRegionEditing = false;
    },
    sheetEvent(callingCode, regionCode) {
      this.formData.isPhoneNumValid = null;
      this.formData.isVerificationCodeValid = null;
      this.formData.currentCallingCode = callingCode;
      this.formData.currentRegionCode = regionCode;
      this.closeEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  font-size: 14px;
  height: 38px;
}
.phoneNum-input {
  .region {
    width: 86px;
    font-size: 14px;
    background-color: white;
    color: $font-normal;
    border-right: none;
    border-radius: 4px 0 0 4px;
    border-color: #ced4da;
    &:active {
      background: white;
      color: $font-normal;
      border-color: #ced4da;
    }
    span {
      font-size: 14px;
      height: 20px;
    }
    i {
      font-size: 16px;
      color: $primary;
    }
  }
}
.error {
  margin-top: 4px;
  height: 16px;
  color: $negative;
  font-size: 12px;
  text-align: start;
}
.send-verification-button {
  height: 38px;
  width: 160px;
  margin-bottom: 16px;
  &.countdown {
    background-color: #ced4da;
    border-color: #ced4da;
  }
  &.invalid {
    margin-bottom: 36px;
  }
}
// .phoneNum-input {
//   width: 350px;
// }
// .verificationCode-input {
//   width: 230px;
// }
.submit-button {
  height: 48px;
}
.previos-page {
  color: $primary;
  bottom: 26px;
}
.list-group-item-action {
  height: 51px;
  border-bottom: 1px solid rgba(199, 199, 199, 0.3);
  color: $font-normal;
}
.dropdown {
  width: 90px;
  height: 38px;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  .customDropdown {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-menu {
    li {
      width: 240px;
    }
  }
}
</style>
