<template>
  <div class="wrap-notice d-flex p-3">
    <div v-if="!$device.isMobile"><i class="icon-Elert-info"></i></div>
    <ol v-if="isBind" class="pl-4 mb-0">
      <li>
        {{
          $t('你已完成綁定，可以用該{type}登入帳號。 ', {
            type: isEmailSignUp ? '手機號碼' : '信箱',
          })
        }}
      </li>
      <li>
        {{
          $t('原帳號發生無法登入等問題，可以透過綁定的{type}恢復帳號。', {
            type: isEmailSignUp ? '手機號碼' : '信箱',
          })
        }}
      </li>
      <li>
        {{
          $t(
            '手機號碼及信箱只會被用來寄送黑嘉嘉圍棋教室通知簡訊/郵件；我們或任何第三方公司不會透過這個聯絡資料寄送垃圾郵件/簡訊。'
          )
        }}
      </li>
    </ol>
    <ol v-else class="pl-4 mb-0">
      <li>
        {{
          $t('完成綁定後，可以用該{type}登入帳號。', {
            type: isEmailSignUp ? '手機號碼' : '信箱',
          })
        }}
      </li>
      <li>
        {{
          $t('原帳號發生無法登入等問題，可以透過綁定的{type}恢復帳號。', {
            type: isEmailSignUp ? '手機號碼' : '信箱',
          })
        }}
      </li>
      <li>
        {{
          $t(
            '手機號碼及信箱只會被用來寄送黑嘉嘉圍棋教室通知簡訊/郵件；我們或任何第三方公司不會透過這個聯絡資料寄送垃圾簡訊/郵件。'
          )
        }}
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    isBind: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isEmailSignUp() {
      return this.$store.getters['user/isEmailSignUp'];
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-notice {
  background: $grayscale-5;
  border-radius: 10px;
  color: $font-grayscale-1;
  line-height: 28px;
  .icon-Elert-info {
    font-size: 24px;
    position: relative;
    top: 2px;
  }
}
</style>
